import { connect } from 'react-redux'
import Page from '../Components/index'


const mapStateToProps = state => ({
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Page)
